import {isMobile} from '../../../../../../commons/selectors/environment'
import {getComponentConfig} from '../../../selectors/settings'
import {AppProps} from '../../app/interfaces'
import {connect} from '../../runtime-context/connect'
import {TodayButton as TodayButtonPresentation} from './today-button'
import {TodayButtonRuntimeProps, TodayButtonOwnProps} from './interfaces'

const mapRuntime = ({state}: AppProps): TodayButtonRuntimeProps => {
  const componentConfig = getComponentConfig(state)

  return {
    isMobile: isMobile(state),
    settings: componentConfig.settings,
  }
}

export const TodayButton = connect<TodayButtonOwnProps, TodayButtonRuntimeProps>(mapRuntime)(TodayButtonPresentation)
export * from './interfaces'
