import {withTranslation} from '@wix/yoshi-flow-editor'
import {getEventDateInformation} from '../../../../../../../../../../../commons/selectors/event'
import {isRibbonVisible} from '../../../../../../../../selectors/settings'
import {
  isDescriptionVisible,
  isImageVisible,
  isMembersVisible,
  isSocialShareVisible,
} from '../../../../../../../../selectors/list-settings'
import {AppProps} from '../../../../../../../app/interfaces'
import {connect} from '../../../../../../../runtime-context/connect'
import {MobileMonthlyEventDetailsOwnProps, MobileMonthlyEventDetailsRuntimeProps} from './interfaces'
import {MobileMonthlyEventDetails as MobileMonthlyEventDetailsPresentation} from './mobile-monthly-event-details'

const mapRuntime = (
  {state, actions}: AppProps,
  {event}: MobileMonthlyEventDetailsOwnProps,
): MobileMonthlyEventDetailsRuntimeProps => {
  return {
    showImage: Boolean(event.mainImage) && isImageVisible(state),
    showDescription: isDescriptionVisible(state),
    showShare: isSocialShareVisible(state),
    showMembers: isMembersVisible(state, event),
    date: getEventDateInformation(state, event.id).fullDate,
    closeMonthlyCalendarEvent: actions.closeMonthlyCalendarEvent,
    showRibbon: isRibbonVisible(state, event),
  }
}

export const MobileMonthlyEventDetails = connect<
  MobileMonthlyEventDetailsOwnProps,
  MobileMonthlyEventDetailsRuntimeProps
>(mapRuntime)(withTranslation()(MobileMonthlyEventDetailsPresentation))
export * from './interfaces'
