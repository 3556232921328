import classNames from 'classnames'
import React from 'react'
import {widgetDataHooks as DH} from '@wix/wix-events-data-hooks'
import {EventImage} from '../../event-image'
import {FullDateLocation} from '../../full-date-location'
import {LinkToPage} from '../../link-to-page'
import {Members} from '../../members'
import {Ribbon} from '../../ribbon'
import {RsvpButton} from '../../rsvp-button'
import {ShortDateLocation} from '../../short-date-location'
import {SocialBar} from '../../social-bar'
import {DynamicStyle} from '../../../../../../commons/components/dynamic-style'
import s from './card.scss'
import {CardProps} from '.'

export class Card extends React.Component<CardProps> {
  state = {
    shouldSetHeight: false,
  }
  private card = React.createRef<HTMLDivElement>()
  private content = React.createRef<HTMLDivElement>()
  private bottom = React.createRef<HTMLDivElement>()
  private details = React.createRef<HTMLDivElement>()
  private topContainer = React.createRef<HTMLDivElement>()
  private title = React.createRef<HTMLDivElement>()
  private contentMarginTop = 24
  private hoveredDateMargin = 30
  private cardHeight = 0
  private timeout = null

  getContentPadding = (): number => {
    const cardHeight = this.getCardHeight()
    const titleScrollHeight = this.title.current?.scrollHeight ?? 0
    const titleClientHeight = this.title.current?.clientHeight ?? 0
    const topHeight = this.topContainer.current?.clientHeight ?? 0
    const bottomHeight = this.bottom.current?.clientHeight ?? 0
    const detailsHeight = this.details.current?.clientHeight ?? 0
    const sum =
      this.contentMarginTop +
      detailsHeight +
      (topHeight - titleClientHeight + titleScrollHeight + this.hoveredDateMargin) +
      bottomHeight

    return (cardHeight - sum) / 2
  }

  onMouseLeave = () => {
    this.content.current.scrollTop = 0
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      this.setState({shouldSetHeight: false})
    }, 1000)
  }

  onMouseEnter = () => {
    clearTimeout(this.timeout)
    this.setState({shouldSetHeight: true})
  }

  getContainerClasses() {
    const {showMembers, hideAdditionalComponents, hasRibbon} = this.props

    return classNames(s.container, {
      [s.noAdditionalComponents]: hideAdditionalComponents,
      [s.noMembers]: !showMembers,
      [s.hasRibbon]: hasRibbon,
    })
  }

  getCardHeight = () => {
    const cardHeight = this.card.current?.clientHeight ?? 0
    if (!this.props.expanded && cardHeight) {
      this.cardHeight = cardHeight
    }

    return this.cardHeight
  }

  componentDidMount() {
    if (!this.cardHeight) {
      this.forceUpdate()
    }
    // there is font layout shift happening if Title fonts are big and title is a little longer.
    // Initial height is measured when title is one line long, and it then becomes two lines long.
    // This timeout catches height change after shift.
    setTimeout(() => {
      const prevCardHeight = this.cardHeight
      const cardHeight = this.getCardHeight()
      if (prevCardHeight !== cardHeight) {
        this.forceUpdate()
      }
    }, 1000)
  }

  render() {
    const {event, showMembers, t, editor, compId, hideAdditionalComponents} = this.props
    const expanded = this.props.expanded && !hideAdditionalComponents
    const namespace = `${compId}-${event.id}-${s.card}`
    const cardHeight = this.getCardHeight()
    const cardHeightWithBorders = `calc((${cardHeight} + (var(--cardBorderWidth) * 2)) * 1px)`
    const contentPadding = this.getContentPadding()
    const detailsHeight = this.details.current?.clientHeight ?? 0

    return (
      <>
        <DynamicStyle namespace={namespace}>
          {`
            &.${s.hoverCard}:hover .${s.content},
            &.${s.hoverCard}.${s.expanded} .${s.content} {
              padding-top: ${contentPadding}px;
              overflow-y: ${contentPadding > 0 ? 'hidden' : 'scroll'};
            }

            &.${s.hoverCard}:hover,
            &.${s.hoverCard}.${s.expanded} {
              height: ${cardHeightWithBorders};
            }
            &.${s.hoverCard}:hover .${s.content} > .${s.cardContentItem},
            &.${s.hoverCard}.${s.expanded} .${s.content} > .${s.cardContentItem} {
              width: ${this.topContainer.current?.clientWidth}px
            }
            &.${s.hoverCard}:hover .${s.detailsContainer},
            &.${s.hoverCard}.${s.expanded} .${s.detailsContainer} {
              height: ${detailsHeight}px;
            }

            .${s.content} {
              margin-top: ${this.contentMarginTop}px;
            }
          `}
        </DynamicStyle>
        <li
          className={classNames(
            s.card,
            namespace,
            expanded ? s.expanded : null,
            cardHeight && !hideAdditionalComponents ? s.hoverCard : null,
            editor && s.disableTransitions,
          )}
          data-hook={DH.card}
          style={{height: this.state.shouldSetHeight ? cardHeightWithBorders : undefined}}
        >
          <div
            className={this.getContainerClasses()}
            ref={this.card}
            onMouseLeave={this.onMouseLeave}
            onMouseEnter={this.onMouseEnter}
          >
            <div className={classNames(s.imageContainer, s.image)}>
              <div className={classNames(s.imageInnerContainer, s.image)}>
                <EventImage event={event} opacityFallback backgroundFallback />
              </div>
            </div>
            <div className={classNames(s.content, s.evCardContentColor)} ref={this.content} data-hook="content">
              <div className={s.cardContentItem} ref={this.topContainer}>
                <Ribbon event={event} className={s.ribbon} />
                <div className={s.title} data-hook="title" ref={this.title}>
                  <LinkToPage event={event}>{event.title}</LinkToPage>
                </div>
                <div className={classNames(s.evCardContentFont, s.shortDateLocation)} data-hook="short-date-location">
                  <ShortDateLocation event={event} />
                </div>
                <div className={classNames(s.evCardContentFont, s.fullDateLocation)}>
                  <FullDateLocation dataHook="full-date-location" event={event} />
                </div>
              </div>
              <div className={classNames(s.cardContentItem, s.detailsContainer)}>
                <div className={classNames(s.details, s.evCardContentFont)} data-hook="details" ref={this.details}>
                  <div className={s.dateLocation}>
                    <FullDateLocation event={event} />
                  </div>
                  {event.description ? (
                    <div className={s.description} data-hook="description">
                      {event.description}
                    </div>
                  ) : null}
                  <div className={s.socialBar}>
                    <SocialBar t={t} event={event} />
                  </div>
                </div>
              </div>
            </div>
            <div className={s.bottomContainer} ref={this.bottom}>
              {showMembers ? (
                <div className={s.members} data-hook="members">
                  <Members event={event} />
                </div>
              ) : null}
              <div className={s.buttonSection} data-hook="button-section">
                <RsvpButton event={event} />
              </div>
            </div>
          </div>
        </li>
      </>
    )
  }
}
