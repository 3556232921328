import {withTranslation} from '@wix/yoshi-flow-editor'
import {isEditor} from '../../../../../commons/selectors/environment'
import {isResponsive, getComponentConfig} from '../../selectors/settings'
import {getEvents} from '../../selectors/events'
import {AppProps} from '../app/interfaces'
import {connect} from '../runtime-context/connect'
import {Cards as CardsPresentation} from './cards'
import {CardsOwnProps, CardsStateProps} from './interfaces'

const mapRuntimeToProps = ({state}: AppProps): CardsStateProps => {
  const events = getEvents(state)
  const responsive = isResponsive(getComponentConfig(state))

  return {
    events,
    editor: isEditor(state),
    responsive,
  }
}

export const Cards = connect<CardsOwnProps, CardsStateProps>(mapRuntimeToProps)(withTranslation()(CardsPresentation))
export * from './interfaces'
