import classNames from 'classnames'
import React from 'react'
import {MainListTitle} from '../../main-list-title'
import {LoadMoreButton} from '../../load-more-button'
import {getEvents, hasMoreEvents} from '../../../selectors/events'
import {getComponentConfig, isListLayout} from '../../../selectors/settings'
import {useWidgetState} from '../../../hooks/state-provider'
import {ListItemContainer} from '../../event-container/list-item-container'
import {CardContainer} from '../../event-container/card-container'
import {isMainTitleVisible} from '../../../selectors/list-settings'
import {isMobile} from '../../../../../../commons/selectors/environment'
import {CardsMobileItem} from './cards-mobile-item'
import s from './cards-mobile.scss'

export const CardsMobile = () => {
  const events = useWidgetState(getEvents)
  const mobile = useWidgetState(isMobile)
  const hasMore = useWidgetState(hasMoreEvents)
  const componentConfig = useWidgetState(getComponentConfig)
  const isDesktopListLayout = isListLayout(componentConfig)
  const mainTitleVisible = useWidgetState(isMainTitleVisible)

  const className = classNames(s.container, isDesktopListLayout ? s.listContainer : s.cardContainer, {
    [s.noTitle]: !mainTitleVisible,
    [s.noBottomPadding]: isDesktopListLayout,
    [s.narrow]: !mobile,
  })

  const Container = isDesktopListLayout ? ListItemContainer : CardContainer

  return (
    <div className={className} data-hook="ev-list-layout">
      <MainListTitle />
      <ul>
        {events.map((event: ExtendedEvent, index: number) => (
          <Container
            key={event.id}
            event={event}
            hideDivider={events.length - 1 === index && !hasMore}
            lastItem={events.length - 1 === index}
            mobile
          >
            {hovered => <CardsMobileItem event={event} hovered={hovered} />}
          </Container>
        ))}
      </ul>
      <LoadMoreButton mobile />
    </div>
  )
}
