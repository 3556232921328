import classNames from 'classnames'
import React from 'react'
import {useTranslation} from '@wix/yoshi-flow-editor'
import sc from '../../classes.scss'
import {Spinner} from '../../spinner'
import {useSettings} from '../../../../hooks/settings'
import settingsParams from '../../../../settingsParams'
import s from './today-button.scss'
import {TodayButtonProps} from '.'

export const TodayButton = ({onClick, dataHook, isMobile, loading, settings}: TodayButtonProps) => {
  const {t} = useTranslation()
  const {get} = useSettings(settings)
  const todayButtonText = get(settingsParams.todayButtonText)
  return (
    <div className={classNames(s.container, {[s.mobile]: isMobile})}>
      {loading ? <Spinner className={s.loader} diameter={20} calendarButton /> : null}
      <button
        data-hook={dataHook}
        onClick={() => onClick()}
        type="button"
        className={classNames(s.button, sc.ellipsis, {
          [s.mobile]: isMobile,
          [s.loading]: loading,
        })}
      >
        {todayButtonText || t('layout.calendar.monthly.controls.today')}
      </button>
    </div>
  )
}
