import {getFormattedFullLocation} from '@wix/wix-events-commons-statics'
import classNames from 'classnames'
import React from 'react'
import ChevronUp from 'wix-ui-icons-common/ChevronUp'
import sc from '../../../../../../../classes.scss'
import {EventImage} from '../../../../../../../event-image'
import {LinkToPage} from '../../../../../../../link-to-page'
import {Members} from '../../../../../../../members'
import {Ribbon} from '../../../../../../../ribbon'
import {RsvpButton} from '../../../../../../../rsvp-button'
import {SocialBar} from '../../../../../../../social-bar'
import s from './mobile-monthly-event-details.scss'
import {MobileMonthlyEventDetailsProps} from '.'

export class MobileMonthlyEventDetails extends React.PureComponent<MobileMonthlyEventDetailsProps> {
  handleEventClick = () => {
    this.props.closeMonthlyCalendarEvent()
  }

  render() {
    const {event, showImage, showDescription, showShare, showMembers, date, t, showRibbon} = this.props
    return (
      <div className={s.container}>
        <div className={s.eventInfo} onClick={this.handleEventClick} data-hook={`event-details-${event.id}`}>
          {showRibbon ? <Ribbon event={event} className={s.ribbon} /> : null}
          <div className={classNames(s.fullDate, sc.textLineHeight)} data-hook="date">
            {date}
          </div>
          <div className={classNames(s.title, sc.textLineHeight)} data-hook="title">
            <LinkToPage event={event}>{event.title}</LinkToPage>
            <div className={s.chevron}>
              <ChevronUp size="1.2em" />
            </div>
          </div>
        </div>
        {showImage ? (
          <div className={s.image}>
            <EventImage event={event} />
          </div>
        ) : null}
        <div className={s.location} data-hook="location">
          {getFormattedFullLocation(event)}
        </div>
        {showMembers ? (
          <div className={s.members} data-hook="members">
            <Members event={event} />
          </div>
        ) : null}
        {showDescription && event.description ? (
          <div className={s.description} data-hook="description">
            {event.description}
          </div>
        ) : null}
        {showShare ? (
          <div className={s.share} data-hook="share">
            <div className={s.shareText}>{t('shareEventText')}</div>
            <SocialBar t={t} spacing={7} event={event} iconFontSize={14} row hideTitle />
          </div>
        ) : null}
        <div className={s.button}>
          <RsvpButton
            event={event}
            dataHook="rsvp-button"
            minWidth={88}
            maxWidth={170}
            additionalStyle={{minHeight: 32, fontSize: 14}}
          />
        </div>
      </div>
    )
  }
}
