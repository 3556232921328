import React, {useEffect, useReducer, useRef} from 'react'
import {widgetDataHooks as DH} from '@wix/wix-events-data-hooks'
import {MainListTitle} from '../main-list-title'
import {LoadMoreButton} from '../load-more-button'
import {Card} from './card'
import s from './cards.scss'
import {CardsProps} from '.'

export const Cards = ({events, t}: CardsProps) => {
  const container = useRef<HTMLDivElement>()
  const [, forceUpdate] = useReducer(x => x + 1, 0)

  useEffect(() => {
    const onResize = () => {
      forceUpdate()
    }
    window.addEventListener('resize', onResize)
    return () => window.removeEventListener('resize', onResize)
  }, [])

  return (
    <div ref={container} className={s.container}>
      <MainListTitle />
      <ul className={s.cards} data-hook={DH.cards}>
        {events.map(event => (
          <Card event={event} t={t} key={event.id} />
        ))}
      </ul>
      <LoadMoreButton />
    </div>
  )
}
