import classNames from 'classnames'
import React from 'react'
import {widgetDataHooks as DH} from '@wix/wix-events-data-hooks'
import sc from '../classes.scss'
import {useSettings} from '../../../hooks/settings'
import settingsParams from '../../../settingsParams'
import s from './main-list-title.scss'
import {MainListTitleProps} from '.'

export const MainListTitle = ({listLayout, mobile, visible, cardsLayout, settings}: MainListTitleProps) => {
  const {get} = useSettings(settings)
  const title = get(settingsParams.listGeneralTitle)

  if (!visible) {
    return null
  }

  return (
    <h1
      className={classNames(s.title, sc.textLineHeight, {
        [s.mobile]: mobile,
        [s.cards]: cardsLayout,
        [s.listLayout]: listLayout,
        [s.defaultLayout]: !mobile && !listLayout && !cardsLayout,
      })}
      data-hook={DH.listTitle}
    >
      {title}
    </h1>
  )
}
