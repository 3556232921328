import {getFormattedFullLocation} from '@wix/wix-events-commons-statics'
import React from 'react'
import {widgetDataHooks as DH} from '@wix/wix-events-data-hooks'
import {useEventDateInformation} from '../../../../../commons/hooks/dates'
import s from './full-date-location.scss'
import {FullDateLocationProps} from '.'

export const FullDateLocation: React.FC<FullDateLocationProps> = ({event, dataHook}) => {
  const {fullDate} = useEventDateInformation(event.id)

  return (
    <div data-hook={dataHook ?? DH.fullDateLocation}>
      <div className={s.date} data-hook="date">
        {fullDate}
      </div>
      <div className={s.location} data-hook="location">
        {getFormattedFullLocation(event)}
      </div>
    </div>
  )
}
