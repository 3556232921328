import React from 'react'
import {widgetDataHooks as DH} from '@wix/wix-events-data-hooks'
import classNames from 'classnames'
import {useAdditionalItemsToggle} from '../../../hooks/use-additional-items-toggle'
import {CardContainerProps} from './interfaces'
import s from './card-container.scss'

export const CardContainer = ({event, children, lastItem}: CardContainerProps) => {
  const {hovered, events} = useAdditionalItemsToggle({event})

  return (
    <li {...events} data-hook={DH.listItem} className={classNames(s.container, {[s.last]: lastItem})}>
      {children(hovered)}
    </li>
  )
}
