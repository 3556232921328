import {isRibbonVisible} from '../../../selectors/settings'
import {
  isDescriptionVisible,
  isImageVisible,
  isMembersVisible,
  isSocialShareVisible,
} from '../../../selectors/list-settings'
import {AppProps} from '../../app/interfaces'
import {connect} from '../../runtime-context/connect'
import {SideBySideItemOwnProps, SideBySideItemStateProps} from './interfaces'
import {SideBySideItem} from './side-by-side-item'

const mapRuntime = (
  {state, host: {dimensions}}: AppProps,
  {event}: SideBySideItemOwnProps,
): SideBySideItemStateProps => {
  return {
    showImage: isImageVisible(state),
    showDescription: isDescriptionVisible(state),
    showSocialBar: isSocialShareVisible(state),
    showMembers: isMembersVisible(state, event),
    membersEnabled: state.membersEnabled,
    showRibbon: isRibbonVisible(state, event),
  }
}

export default connect<SideBySideItemOwnProps, SideBySideItemStateProps>(mapRuntime)(SideBySideItem)
export * from './interfaces'
